import { collection, doc, onSnapshot } from "firebase/firestore";
import { FC, useEffect, useState } from "react";
import { balanceTrxV2Converter, transactionsConverter } from "src/converters";
import UserTransactions from "./userTransactions";
import { db, generateFirestorePath } from "src/helpers";
import { Divider } from "antd";
import UserStateDetails from "src/components/internal/components/userState/userStateDetails";
import { UserTournament } from "./userTournaments";

interface Props {
  user: AppUser & AppUserPublic;
  userState?: UserState;
  userTournament: UserTournament[];
  expandedView?: boolean;
}

type TrxData =
  | (BalanceTransaction & { colVersion: "1" | "2" })
  | ChallengeEntryTrx;
const TransactionDetails: FC<Props> = ({ user, userState, expandedView }) => {
  const [transactions, setTransactions] = useState<{
    [transactionId: string]: TrxData;
  } | null>(null);

  useEffect(() => {
    const userRef = collection(db, generateFirestorePath("appUsers"));

    const userDoc = doc(userRef, user.uid);

    const balanceTransactionRef = collection(
      userDoc,
      generateFirestorePath("balanceTransactions")
    ).withConverter(transactionsConverter);

    const balanceTransactionV2Ref = collection(
      userDoc,
      generateFirestorePath("balanceTransactionsV2")
    ).withConverter(balanceTrxV2Converter);

    const unsubTournCol = onSnapshot(balanceTransactionRef, (colSnap) => {
      const defs: typeof transactions = {};
      for (const tourDefSnap of colSnap.docs) {
        const tourDefId = tourDefSnap.id;
        const tourDef = tourDefSnap.data();
        defs[tourDefId] = { ...tourDef, colVersion: "1" };
      }
      setTransactions((curr) => ({ ...curr, ...defs }));
    });

    const unsubTournV2Col = onSnapshot(balanceTransactionV2Ref, (colSnap) => {
      const defs: typeof transactions = {};
      for (const tourDefSnap of colSnap.docs) {
        const tourDefId = tourDefSnap.id;
        const tourDef = tourDefSnap.data();
        defs[tourDefId] = {
          ...tourDef,
          colVersion: "2",
          tournamentId: tourDef.metadata?.tournamentId,
          version: tourDef.transactionVersion || "2",
        } as unknown as TrxData;
      }
      setTransactions((curr) => ({ ...curr, ...defs }));
    });

    return () => {
      unsubTournCol();
      unsubTournV2Col();
    };
  }, [user.uid]);

  return (
    <>
      {userState && (
        <>
          <UserStateDetails
            hideUsername
            details={{ ...userState, id: userState.appUserUid }}
          />

          <Divider />
        </>
      )}
      <UserTransactions
        expandedView={expandedView}
        transactions={transactions}
      />
    </>
  );
};

export default TransactionDetails;
